// I don't think this has ever used bootstrap and have no idea why it was here.
// import 'bootstrap/dist/css/bootstrap.min.css';
import ContentContainer from './pages/ContentContainer.js';
import './App.css';

function App() {
  return (
    <ContentContainer />
  );
};

export default App;
